import { ROOT_DOMAIN } from '../globals'

// export const fbDescription = 'Start a Landslide to crush Trump and the GOP. Track your impact. Multiply your power. '
// export const twitterDescription = ' Start a Landslide to crush Trump and the GOP. Track your impact. Multiply your power. '

export const partisanTitle = "Trump is Trying to Steal the Election."
export const partisanDescription = "Find out how to stop him in your state."

export const nonPartisanTitle = "Make Sure your Vote Gets Counted by Election Night."
export const nonPartisanDescription = "Get the best voting options for your state"

export const defaultTitle = 'Landslide 2020'
// export const defaultDescription = fbDescription
export const defaultOGURL = 'https://landslide2020.org'
export const defaultOGImage = ROOT_DOMAIN + '/static/OG-partisan.jpg'
export const nonPartisanOGImage = ROOT_DOMAIN + '/static/OG-nonpartisan.jpg'
export const shareOGImage = ROOT_DOMAIN + '/static/OG-share.jpg'
export const v1OGImage = ROOT_DOMAIN + '/static/og-v1.jpg'
export const defaultTwitterImage = ROOT_DOMAIN + '/static/og_vote-early-sm.jpg'
export const googleSearchConsoleTags = 'M-va6Kc3TPalH7WgCPMwG_wLkUXX42SO3iei7hfyKHc'