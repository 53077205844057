import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Head from './head'
import GA from './GA'
import scrollToTop from '../lib/scrollToTop'

function Layout({ className, url, title, children, fullScreen, noStyle, nonPartisan, share, v1 }) {
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <GA>
      <Head url={url} title={title} nonPartisan={nonPartisan} share={share} v1={v1} />
      {noStyle ? (
        children
      ) : (
          <div className="layout-wrapper">
            <div className={`layout ${fullScreen ? 'fullscreen' : ''} ${className}`}>{children}</div>
          </div>
        )}
    </GA>
  )
}

Layout.defaultProps = {
  bg: '',
  fullScreen: false,
  className: '',
  title: 'Landslide',
  noStyle: false,
  nonPartisan: false,
  share: false,
  v1: false,
}

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  url: PropTypes.string,
  title: PropTypes.string,
  fullScreen: PropTypes.bool,
  noStyle: PropTypes.bool,
  nonPartisan: PropTypes.bool,
  share: PropTypes.bool,
  v1: PropTypes.bool,
}

export default Layout
